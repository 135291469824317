<template>
  <Main>
    <div class="orders">
      <div class="orders__container">
        <div class="orders__header">
          <h1>Заказы</h1>
          <div class="orders__toggle-moderated">
            <div
              :class="['orders__toggle-item', status === 'waiting' ? 'active' : '']"
              @click="handleChangeStatus('waiting')"
            >
              Заказ на модерации
              <span v-if="notificationOrder != 0" class="orders__no-read"></span>
            </div>
            <div
              :class="['orders__toggle-item', status === 'moderated' ? 'active' : '']"
              @click="handleChangeStatus('moderated')"
            >
              Заказ прошёл модерацию
              <!-- <span v-if="notificationDriverModeratedAfterEdit != 0" class="orders__no-read"></span> -->
            </div>
            <div
              :class="['orders__toggle-item', status === 'all' ? 'active' : '']"
              @click="handleChangeStatus('all')"
            >
              Вce
            </div>
          </div>
          <div class="orders__buttons">
            <ButtonMain @click.native="handelPageCreate">Создать заказ</ButtonMain>
            <InputSearch
              :label="'search'"
              :search="searchOrders"
              :placeholder="'Поиск по телефону заказчика и водителя'"
            />
          </div>
        </div>
        <CustomTable
          v-if="ordersList.length"
          :data="ordersList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :direction="directionSort"
          :order="orderSort"
          :handleFilter="handleFilter"
          :handleCancel="handleCancelOrder"
          :handle-assign-driver="handleAssignDriver"
          :handle-copy="handleOrderCopy"
          :handle-edit="toUpdateOrder"
          :on-custom-style-column="tableOptions.onCustomStyleColumn"
          :name="'order'"
        >
          <template v-slot:footer>
            <div class="pagination">
              <Pagination
                :maxPage="orders.meta.lastPage"
                :page="page"
                :handlePageChange="handlePageChange"
              />
            </div>
          </template>
        </CustomTable>
      </div>
    </div>
    <DefaultModal
      class="orders__confirm-modal"
      v-if="cancelModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalSecond :on-cancel="handleCloseConfirmModal" :on-success="handleCancel">
        <template v-slot:description>
          <p>Укажите причину отмены заказа</p>
        </template>
        <template v-slot:cancel> Не отменять </template>
        <template v-slot:success> Отменить заказ </template>
      </ConfirmModalSecond>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";

import Main from "../templets/Main.vue";
import CustomTable from "../components/molecules/CustomTable.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalSecond from "../components/molecules/ConfirmModalSecond.vue";
import InputSearch from "../components/atoms/inputs/InputSearch.vue";
import Pagination from "../components/molecules/Pagination.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
export default {
  name: "Orders",
  data() {
    return {
      search: "",
      directionSort: false,
      status: "all",
      orderSort: "id",
      rowToCancel: "",
      cancelModal: false,
      tableOptions: {
        deletedKeys: ["null", "driver_id"],
        isActionBlockVisible: true,
        onCustomStyleColumn: [
          {
            columnName: "address_from_short",
            className: "column-wrap",
          },
        ],
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "address_from_short",
            to: "Маршрут",
          },
          {
            from: "driver_last_name",
            to: "Водитель",
          },
          // {
          //   from: "driver_phone",
          //   to: "Телефон водителя",
          // },
          {
            from: "client_name",
            to: "Заказчик",
          },
          {
            from: "client_phone",
            to: "Телефон заказчика",
          },
          {
            from: "date",
            to: "Дата",
          },
          {
            from: "time",
            to: "Время",
          },
          {
            from: "rate",
            to: "Тариф, грн",
          },
          {
            from: "total_price",
            to: "Цена, грн",
          },
          {
            from: "status",
            to: "Статус",
          },
        ],
        customOrder: [
          "id",
          "address_from_short",
          "driver_last_name",
          "driver_id",
          // "driver_phone",
          "client_name",
          "client_phone",
          "date",
          "time",
          "rate",
          "total_price",
          "status",
        ],
      },
    };
  },
  components: {
    Main,
    CustomTable,
    FetchSpinnerModal,
    DefaultModal,
    ConfirmModalSecond,
    InputSearch,
    Pagination,
    ButtonMain,
  },
  methods: {
    handlePageChange(page) {
      this.$router.push({
        path: ROUTE.ORDERS_PAGE.replace(":page", page),
      });
      this.getOrdersList();
    },
    handelPageCreate() {
      this.$router.push({
        path: ROUTE.ORDER_CREATE_PAGE,
      });
    },
    handleAssignDriver(row) {
      this.$router.push({
        path: ROUTE.ETHER_ADD_DRIVER_PAGE.replace(":orderId", row.id),
        query: { reAssign: "true" },
      });
    },
    getOrdersList() {
      let url = `?page=${this.page}`;
      if (this.search.length) {
        url = url + `&search=${this.search}`;
      }
      if (this.orderSort.length) {
        url = url + `&order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      }

      if (this.status === "waiting") {
        url = url + "&status=waiting_dispatcher";
      }

      if (this.status === "moderated") {
        url = url + "&status=moderated_dispatcher";
      }
      this.$store.dispatch("order/getOrdersList", url);
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getOrdersList();
    },
    searchOrders(data) {
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.ORDERS_PAGE.replace(":page", 1),
        });
      }
      this.search = data;
      const url = `?page=1&search=${data}`;
      this.$store.dispatch("order/getOrdersList", url);
    },
    handleCancel(data) {
      const url = `/${this.rowToCancel.id}`;
      const dataSend = {
        reason_for_cancellation: data,
      };
      this.$store.dispatch("order/cancelOrder", { url: url, dataSend: dataSend }).finally(() => {
        this.getOrdersList();
      });
      this.handleCloseConfirmModal();
    },
    handleCloseConfirmModal() {
      this.rowToCancel = null;
      this.cancelModal = false;
    },
    handleCancelOrder(row) {
      this.rowToCancel = row;
      this.cancelModal = true;
    },
    handleOrderCopy(row) {
      this.$router.push({
        path: ROUTE.ORDER_DETAIL_PAGE.replace(":orderId", row.id),
      });
    },
    toUpdateOrder(row) {
      this.$router.push({
        path: ROUTE.ORDER_CREATE_PAGE.replace(":process", "update").replace(":orderId", row.id),
      });
    },
    handleChangeStatus(status) {
      this.status = status;
      if (this.status == "waiting") {
        this.$store.dispatch("notification/notificationViewed", { types: ["order_created"] });
        setTimeout(() => {
          this.$store.commit("notification/setNotificationOrder", 0);
        }, 1000);
      }
      this.getOrdersList();
    },
  },
  computed: {
    ...mapState("order", ["orders", "ordersList", "loadingProcess"]),
    ...mapState("notification", ["notificationOrder"]),
    page() {
      return +this.$route.params.page;
    },
  },
  mounted() {
    this.getOrdersList();
  },
};
</script>

<style lang="scss" scoped>
.orders {
  width: 100%;

  &__container {
    padding: 25px 17px;
  }

  &__toggle-moderated {
    @include flex-row;

    justify-content: space-between;
    overflow: hidden;
    width: 500px;
    height: 48px;
    border-radius: 5px;
  }

  &__toggle-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(33% - 2px);
    height: 100%;
    background: $mainDisabled;
    font-size: 14px;
    font-weight: 500;
    color: $mainColor;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    text-align: center;

    &.active {
      background: $mainColor;
      color: $g-color-white;
    }
  }

  &__no-read {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #eb5959;
    border-radius: 50px;
    top: 5px;
    right: 5px;
  }

  &__header {
    @include flex-row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    /deep/.search-input {
      width: 322px;
    }
  }

  &__buttons {
    @include flex-row;

    align-content: center;

    /deep/.button-main {
      width: 200px;
      height: 36px;
    }
    /deep/.search-input {
      margin-left: 20px;
      width: 400px;
    }
  }
}
/deep/.custom-table__header {
  grid-template-columns: 5% 17% 16% 10% 8% 7% 6% 7% 6% 7% 11% !important;
}
/deep/.custom-table__row {
  grid-template-columns: 5% 17% 16% 10% 8% 7% 6% 7% 6% 7% 11% !important;
}
/deep/.custom-table__action-block {
  justify-content: flex-end;
}
/deep/.column-wrap {
  div {
    white-space: pre-wrap;
  }
}
</style>
